import React from "react";
import SectionHeader from "../SectionHeader";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Grid, Box } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
//  items='1' loop margin={10} nav={false} autoplay={true} dots={false}

const testimonialphoneviewOptions = {
  margin: 10,
  responsiveClass: true,
  stagePadding: 300,
  nav: false,
  dots: false,
  loop: true,
  autoplay: true,
  autoplayTimeout: 4000,
  autoplaySpeed: 4000,
  smartSpeed: 1000,
  items: 1,
  responsive: {
    0: {
      stagePadding: 20,
    },
    400: {
      stagePadding: 20,
    },
    600: {
      stagePadding: 20,
    },
    1441: {
      stagePadding: 100,
    },
  },
};

const testimonialscarouselOptions = {
  margin: 10,
  responsiveClass: true,
  stagePadding: 300,
  nav: false,
  dots: false,
  loop: true,
  autoplay: true,
  autoplayTimeout: 4000,
  autoplaySpeed: 4000,
  smartSpeed: 1000,
  items: 3,
  responsive: {
    0: {
      stagePadding: 80,
    },
    400: {
      stagePadding: 80,
    },
    600: {
      stagePadding: 30,
    },
    1441: {
      stagePadding: 80,
    },
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const testimonialContent = [
  {
    imgsrc: "images/faces/Kiran-Dalal.jpg",
    testimonialName: "Kiran Dalal",
    designation: "Principal",
    header: "Work Ethics",
    content:
      "Memories Maker’s work ethic is different and impressive. They are true to their name! The tours organized by them are truly tailor-made according to the students’ needs. Their professional and positive attitude make every trip a memorable one. In the pandemic, they had launched Live Virtual tours which were like a cherry on the cake to their services. It gave international exposure to the students in these tough situations. I am looking forward to organising many international trips with them when time and situation permit. Good luck Memories Maker team and keep up the good work!",
  },
  {
    imgsrc: "images/faces/Anu-Bhatia.jpg",
    testimonialName: "Anu Bhatia",
    designation: "Principal",
    header: "Management Quality",
    content:
      "Dear members of team memories maker<br/> Bonjour! We are extremely grateful to you for organizing such an amazing virtual tour.It was just a blessing in that pandemic time where children were sitting at home and missing the outside world.This initiative taken by you is the complete amalgamation of learning and fun where live talks and quiz was like cherry on the cake.<br/>I can express it  as SUPER<br/>Blessings and looking forward to have many more in future ahead",
  },
  {
    imgsrc: "images/faces/Prem-Ojha.jpg",
    testimonialName: "Prem Ojha",
    designation: "Principal",
    header: "Management Quality",
    content:
      "When everything is changing, Memories Maker has changed the education system by inculcating Travel. The amalgamation of Travel and Education has made learning easier for the students. My school has opted many domestic and international tours with them and I must say that our experience was stupendous. From booking to landing to coming back to India, everything was top notch. They truly believe in giving experience of the most amazing days of your lives. We are so blessed and happy that we had organised our tours with Memories Maker. We wish the team good luck and success! Looking forward to organising many tours with them!",
  },
  {
    imgsrc: "images/faces/dhriti-malhotra.jpg",
    testimonialName: "Ms. Dhriti Malhotra",
    designation: "Director Principal",
    header: "Service Quality",
    content:
      "In Manav Rachna International School, Sector-46, the French Department organized a virtual tour in collaboration with Memories Maker to give children a different learning experience along with international travel Live from the comfort of their home. It was a sheer enriching experience where kids could virtually visit and enjoy the beauty of France. Great job done by memories maker. Thanks a lot. They organise events in a very professional way. Really enjoyed Paris Comes Home , ‘चलते चलते French’ and looking forward to more events.",
  },
  {
    imgsrc: "images/faces/VictoriaSangita.webp",
    testimonialName: "Victoria De Souza",
    designation: "Director Principal",
    header: "Service Quality",
    content:
      "It was a lovely experience more than what I expected .I am carrying home beautiful memories crafted by Nitin & Pratiksha along with all the ones involved in making our trip so comfortable & enjoyable . The stay , food was excellent with add ons coming unexpectedly from Nitin .The entire itenary was meticulously planned & executed. Nitin took special care to see that everyone was comfortable & enjoying themselves .The best part was that Nitin was very flexible & made even last minute changes to satisfy us throughly. Well done Memories Makers. Looking forward for more memories.",
  },
];

function Testimonials() {
  return (
    <>
      <div className="customContainer" id="testimonialSection">
        <SectionHeader firstTxt="Our" lastTxt="Reviews" />
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <OwlCarousel
            className="owl-theme feedbackcarouselnew"
            {...testimonialscarouselOptions}
          >
            {testimonialContent.map((val) => {
              return (
                <div className="item">
                  <div className="row">
                    <div className="col">
                      <div className="testimonial mb-5">
                        <img src={val.imgsrc} />
                        <div className="name">{val.testimonialName}</div>
                        <div className="designation">{val.designation}</div>
                        <div>
                          <img
                            src="images/faces/5-star-review-google-reviews.png"
                            style={{
                              width: "100px",
                              height: "28px",
                              borderRadius: "100px",
                            }}
                          />
                        </div>
                        <p>{val.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </Box>

        <Box sx={{ display: { md: "none", xs: "block" } }}>
          <OwlCarousel {...testimonialphoneviewOptions}>
            {testimonialContent.map((val) => {
              return (
                <div
                  className="item"
                  //   style={{ width: "336px", marginRight: "20px" }}
                >
                  <div className="row">
                    <div className="col">
                      <div className="testimonial" style={{ height: "730px" }}>
                        <img src={val.imgsrc} />
                        <div className="name">{val.testimonialName}</div>
                        <div className="designation">{val.designation}</div>
                        <div>
                          <img
                            src="images/faces/5-star-review-google-reviews.png"
                            style={{
                              width: "100px",
                              height: "28px",
                              borderRadius: "100px",
                            }}
                          />
                        </div>
                        <p>{val.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </Box>
      </div>
    </>
  );
}

export default Testimonials;
