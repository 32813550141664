import React, { useState } from "react";
import "./App.css";
import {
  AppBar,
  Checkbox,
  Grid,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Button,
  MenuItem,
} from "@mui/material";
import {
  RiGoogleLine,
  RiFacebookLine,
  RiTwitterFill,
  RiInstagramLine,
  RiWhatsappLine,
  RiPinterestFill,
} from "react-icons/ri";
import axios from "axios";
import { ValidatorComponent } from "react-material-ui-form-validator";

import { GiHamburgerMenu } from "react-icons/gi";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import MuiAlert from "@mui/material/Alert";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { BsEmojiSmile } from "react-icons/bs";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pages = [
  "",
  "about",
  "about",
  "about",
  "founder",
  "companyProfile",
  "gallery",
  "news",
  "contact",
  "",
  "blog",
];

function Header() {
  const [checked, setChecked] = React.useState(true);

  const checkboxHandle = (event) => {
    setChecked(event.target.checked);
  };

  // QUERY FORM
  const [queryValue, setQueryValue] = useState({
    userName: "",
    userSchoolName: "",
    userPhone: "",
    userMail: "",
    userDepartureCity: "india",
    userDestination: "dubai",
  });

  // MAIL FORM
  const [mailValue, setMailValue] = useState({
    userName: "",
    userSchoolName: "",
    userPhone: "",
    userMail: "",
    userMessage: "",
  });

  const setQueryFormValue = (e) => {
    let inpName = e.target.name;
    let inpValue = e.target.value;

    setQueryValue((preValue) => {
      return {
        ...preValue,
        [inpName]: inpValue,
      };
    });
  };

  const setMailFormValue = (e) => {
    let inpName = e.target.name;
    let inpValue = e.target.value;

    setMailValue((preValue) => {
      return {
        ...preValue,
        [inpName]: inpValue,
      };
    });
  };

  // sending data to backend
  const querySubmission = (e) => {
    e.preventDefault();

    if (
      queryValue.userName != "" &&
      queryValue.userSchoolName != "" &&
      queryValue.userPhone != "" &&
      queryValue.userMail != "" &&
      queryValue.userDepartureCity != "" &&
      queryValue.userDestination != ""
    ) {
      axios
        .post(`https://memoriesmakeredu.com/api/index.php`, {
          ...queryValue,
          date: dateValue,
        })
        .then((response) => {
          if (response.data == "success") {
            // setValState({teacherName:'', schoolName:'', phoneNumber:'', designation:'', mailId:''});
            // handleOpen(true);
            alert("Will Get Back To You Soon");
            setOpen(false);
            setQueryValue({
              userName: "",
              userSchoolName: "",
              userPhone: "",
              userMail: "",
              userDepartureCity: "india",
              userDestination: "dubai",
            });
            setDateValue(null);
          } else {
            // failureBoxhandleOpen(true);
            alert("data not sent, something went wrong");
          }
        });
    } else {
      alert("Please Fill All The Feilds");
    }
  };

  // login form
  const [loginFormValue, setLoginFormValue] = useState({
    loginUserId: "",
    loginUserPassword: "",
  });

  const setLoginForm = (e) => {
    let inpName = e.target.name;
    let inpVal = e.target.value;

    setLoginFormValue((preval) => {
      return { ...preval, [inpName]: inpVal };
    });
  };

  //login submission
  const loginSubmission = (e) => {
    e.preventDefault();

    if (
      loginFormValue.loginUserId != "" &&
      loginFormValue.loginUserPassword != ""
    ) {
      // alert('success form submit');
      axios
        .post("https://memoriesmakeredu.com/api/index.php", {
          ...loginFormValue,
        })
        .then((response) => {
          alert(response.data);
          console.log(response.data);
        });
    } else {
      alert("Please Fill All The Feilds");
    }
  };

  // mail form
  const [mailAlert, setMailAlert] = useState(false);
  const mailAlertClose = () => setMailAlert(false);

  const mailSubmission = (e) => {
    e.preventDefault();
    if (
      mailValue.userName != "" &&
      mailValue.userSchoolName != "" &&
      mailValue.userPhone != "" &&
      mailValue.userMail != "" &&
      mailValue.userMessage != ""
    ) {
      axios
        .post(`https://memoriesmakeredu.com/api/index.php`, { ...mailValue })
        .then((response) => {
          if (response.data == "success") {
            setMailopen(false);
            setMailValue({
              userName: "",
              userSchoolName: "",
              userPhone: "",
              userMail: "",
              userMessage: "",
            });
            setMailAlert(true);
          } else {
            alert("Quick Enquiry Mail Not Sent");
          }
        });
    } else {
      alert("Please Fill All The Feilds");
    }
  };

  // signup form

  const [signupFormValue, setSignupFormValue] = useState({
    signupUserId: "",
    signupUserPassword: "",
    signupConfirmPassword: "",
  });

  const setSignupForm = (e) => {
    let signupInpName = e.target.name;
    let signupInpVal = e.target.value;

    setSignupFormValue((preval) => {
      return { ...preval, [signupInpName]: signupInpVal };
    });
  };

  //login submission
  const signupSubmission = (e) => {
    e.preventDefault();
    alert("form submitted");
  };

  const [dateValue, setDateValue] = useState(null);
  const [open, setOpen] = useState(false);
  const queryBoxOpen = () => setOpen(true);
  const queryBoxClose = () => setOpen(false);

  const [mailopen, setMailopen] = useState(false);
  const mailBoxOpen = () => setMailopen(true);
  const mailBoxClose = () => setMailopen(false);

  const [loginBox, setLoginBox] = useState(false);
  const loginBoxOpen = () => setLoginBox(true);
  const loginBoxClose = () => setLoginBox(false);

  const [signupBox, setSignupBox] = useState(false);
  const signupBoxOpen = () => setSignupBox(true);
  const signupBoxClose = () => setSignupBox(false);

  const [svalue, setSvalue] = useState();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const isMenuOpen = Boolean(anchorElNav);

  const TourMenu = (e) => {
    setAnchorTourNav(e.currentTarget);
  };
  const closeOurTourMenu = () => {
    setAnchorTourNav(null);
  };

  const openMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorTourMenu, setAnchorTourNav] = useState(null);
  const isOurTourOpen = Boolean(anchorTourMenu);

  const [anchorElNavTour, setAnchorElNavTour] = useState(null);
  const isMenuOpenTour = Boolean(anchorElNavTour);

  const openTourMenu = (e) => {
    setAnchorElNavTour(e.currentTarget);
  };
  const closeTourMenu = () => {
    setAnchorElNavTour(null);
  };

  // ASIDEBAR
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "250px" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton disablePadding sx={{ padding: "0" }}>
            <Link to="/">
              <img
                src="images/logo/MM-Color.png"
                style={{ width: "30px" }}
                alt=""
              />
            </Link>
          </ListItemButton>
        </ListItem>
        {[
          "HOME",
          "ABOUT US",
          "WHAT WE DO ?",
          "MISSION & VISION",
          "MEET OUR FOUNDER",
          "COMPANY PROFILE",
          "GALLERY",
          "NEWS",
          "CONTACT US",
          "SEND US QUERY",
          "BLOGS",
        ].map((text, i) => (
          <>
            <ListItem key={text} disablePadding className="mobileView_tabList">
              <ListItemButton
                style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
              >
                {text === "SEND US QUERY" ? (
                  <Button
                    sx={{ textAlign: "left", width: "100%", padding: "0" }}
                    onClick={queryBoxOpen}
                  >
                    <Link
                      className="tabLink"
                      style={{ width: "100%" }}
                      to={`/${pages[i]}`}
                    >
                      <ListItemText primary={text} />
                    </Link>
                  </Button>
                ) : (
                  <Link
                    style={{ width: "100%" }}
                    className="tabLink"
                    to={`/${pages[i]}`}
                  >
                    <ListItemText primary={text} />
                  </Link>
                )}
              </ListItemButton>
            </ListItem>
          </>
        ))}
        {/* <ListItem>
          <ListItemButton onClick={loginBoxOpen} style={{ color: "#2c3e50" }}>
            Login
          </ListItemButton>
        </ListItem> */}
        {/* <ListItem>
          <ListItemButton onClick={signupBoxOpen} style={{ color: "#2c3e50" }}>
            Register Now
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );

  const alertContent = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={mailAlertClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        open={mailAlert}
        autoHideDuration={4000}
        onClose={mailAlertClose}
      >
        <Alert
          onClose={mailAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Mail Sent Successfully!!
        </Alert>
      </Snackbar>

      <div className="fixedContactIcons">
        <div className="feedbackIcon">
          <a href="https://g.page/r/CTYz_DLFa5H1EAg/review">
            <BsEmojiSmile
              style={{
                transform: "rotate(-90deg)",
                marginRight: "4px",
                color: "#fc4349",
              }}
            />
            Feedback
            <img
              src="images/graphics/feedback-stars.jpg"
              width="30"
              className="feedbackStar"
            />
          </a>
        </div>
        <div className="messageChatIcon">
          <a
            href="javascript:void(0);"
            onClick={mailBoxOpen}
            className="innerBox messageBtn"
            style={{ display: "flex", alignItems: "center" }}
            data-toggle="modal"
            data-target="#quickEnquiryModal"
          >
            {/* <span className="messageIcon"><i className="fa fa-envelope" aria-hidden="true"></i></span> */}
            <img
              src="images/icons/gmail.png"
              style={{ height: "15px", marginRight: "12px" }}
              alt=""
            />
            <span className="messageTxt">Quick Enquiry</span>
          </a>
        </div>
        <div className="whatsappChatIcon">
          <div className="webwhatsappicon">
            <a
              href="https://web.whatsapp.com/send?phone=+919991225544"
              className="innerBox"
              target="_blank"
            >
              <span className="WhatsappIcon">
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
              </span>
              <span className="whatsappTxt">Whatsapp</span>
            </a>
          </div>
          <div className="phonewhatsappicon">
            <a href="whatsapp://send?phone=+919991225544" className="innerBox">
              <span className="WhatsappIcon">
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
              </span>
              <span className="whatsappTxt">Whatsapp</span>
            </a>
          </div>
        </div>

        <div className="callChatIcon">
          <a href="tel:919991225544" className="innerBox">
            <span className="callIcon">
              <i className="fa fa-phone" aria-hidden="true"></i>
            </span>
            <span className="callTxt">Call Us Now</span>
          </a>
        </div>
      </div>

      <Box
        className="parisTourAdvBtnBox"
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Link style={{ textDecoration: "none" }} to="/registrationform">
          <Button>
            <span>Register</span>
            <br />
            <span>Now</span>
          </Button>
        </Link>
      </Box>

      {/* backdropFilter:"blur(20px)",  */}
      <AppBar
        className="mainHeader"
        sx={{ backgroundColor: "transparent", padding: "0px" }}
      >
        <Toolbar
          style={{ backgroundColor: "#0b2540" }}
          className="customToolbar"
        >
          <Button
            sx={{
              minWidth: "20px",
              fontSize: "20px",
              color: "white",
              padding: "0",
              display: { xs: "flex", md: "none" },
            }}
            onClick={toggleDrawer("left", true)}
          >
            <GiHamburgerMenu />
          </Button>
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>

          <Tabs
            value={svalue}
            sx={{ display: { xs: "none", md: "flex" } }}
            onChange={(e, val) => {
              setSvalue(val);
            }}
            TabIndicatorProps={{
              style: { backgroundColor: "#fc4349", color: "green" },
            }}
          >
            <Link className="tabLink" to="/home">
              <Tab label="Home" className="navTab" />
            </Link>
            <Tab
              label="About Us"
              sx={{ color: "white" }}
              className="navTab"
              aria-label="About Us"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openMenu}
            />
            <Link to="/contact" className="tabLink">
              <Tab label="Contact Us" className="navTab" />
            </Link>
            <Link to="/gallery" className="tabLink">
              <Tab label="Gallery" className="navTab" />
            </Link>
            <Button sx={{ padding: "0" }} onClick={queryBoxOpen}>
              <Tab
                label="Send Us Query"
                sx={{ color: "white" }}
                className="navTab"
              />
            </Button>
            <Tab
              label="Our Program"
              className="navTab"
              aria-label="Our Tour"
              aria-controls="tour_menu"
              aria-haspopup="true"
              onClick={TourMenu}
            />
            <Link to="/blog" className="tabLink">
              <Tab label="Our Blogs" className="navTab" />
            </Link>
          </Tabs>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={queryBoxClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box
                className="queryModalBox"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: 2,
                  p: 1,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h2"
                >
                  Send Us a <span style={{ color: "#fc4349" }}>Query</span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <form
                    onSubmit={querySubmission}
                    noValidate
                    autoComplete="off"
                  >
                    <Box sx={{ "& > :not(style)": { mb: 1, width: "100%" } }}>
                      <TextField
                        value={queryValue.userName}
                        onChange={setQueryFormValue}
                        id="userName"
                        type="text"
                        name="userName"
                        label="Name"
                        variant="standard"
                      />
                      <TextField
                        value={queryValue.userSchoolName}
                        id="userSchoolName"
                        onChange={setQueryFormValue}
                        name="userSchoolName"
                        label="School/College Name"
                        type="text"
                        variant="standard"
                      />
                      <TextField
                        id="userPhone"
                        value={queryValue.userPhone}
                        onChange={setQueryFormValue}
                        name="userPhone"
                        label="Phone"
                        variant="standard"
                      />
                      <TextField
                        label="Email Id"
                        value={queryValue.userMail}
                        onChange={setQueryFormValue}
                        type="email"
                        name="userMail"
                        id="userMail"
                        variant="standard"
                      />

                      <Grid
                        container
                        spacing={2}
                        sx={{ marginLeft: "0", marginTop: "0" }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ padding: "0", paddingRight: "10px" }}
                        >
                          <FormControl
                            variant="standard"
                            style={{ m: 1, minWidth: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Departure City
                            </InputLabel>
                            <Select
                              onChange={setQueryFormValue}
                              name="userDepartureCity"
                              sx={{ width: "100%" }}
                              labelId="demo-simple-select-standard-label"
                              id="userDepartureCity"
                              value={queryValue.userDepartureCity}
                              label="Age"
                            >
                              <MenuItem value="india">India</MenuItem>
                              <MenuItem value="dubai">Dubai</MenuItem>
                              <MenuItem value="singapore">Singapore</MenuItem>
                              <MenuItem value="paris">Paris</MenuItem>
                              <MenuItem value="new york">New York</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ paddingLeft: "0", paddingTop: "0" }}
                        >
                          <FormControl
                            variant="standard"
                            style={{ minWidth: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Destination
                            </InputLabel>
                            <Select
                              onChange={setQueryFormValue}
                              name="userDestination"
                              sx={{ width: "100%" }}
                              labelId="demo-simple-select-standard-label"
                              id="userDestination"
                              value={queryValue.userDestination}
                              label="Destination"
                            >
                              <MenuItem value="india">India</MenuItem>
                              <MenuItem value="dubai">Dubai</MenuItem>
                              <MenuItem value="singapore">Singapore</MenuItem>
                              <MenuItem value="paris">Paris</MenuItem>
                              <MenuItem value="new york">New York</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="Travel Date"
                          value={dateValue}
                          name="userDate"
                          onChange={(newValue) => {
                            setDateValue(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <div style={{ display: "flex" }}>
                        <Checkbox
                          checked={checked}
                          onChange={checkboxHandle}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <div>
                          <span style={{ fontSize: "12px" }}>
                            I have read and agree to the User Agreement &amp;
                            Privacy Policy.
                          </span>
                        </div>
                      </div>

                      <Button
                        type="submit"
                        variant="contained"
                        className="navbar_querySubmitBtn"
                        sx={{ backgroundColor: "#fc4349" }}
                      >
                        Get a Callback
                      </Button>
                    </Box>
                  </form>
                </Typography>
              </Box>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={mailopen}
            onClose={mailBoxClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={mailopen}>
              <Box
                className="queryModalBox"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: 2,
                  p: 1,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h2"
                >
                  Quick <span style={{ color: "#fc4349" }}>Enquiry</span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <form onSubmit={mailSubmission} autoComplete="off">
                    <Box sx={{ "& > :not(style)": { mb: 1, width: "100%" } }}>
                      <TextField
                        value={mailValue.userName}
                        onChange={setMailFormValue}
                        id="userName"
                        type="text"
                        name="userName"
                        label="Name"
                        variant="standard"
                      />
                      <TextField
                        value={mailValue.userSchoolName}
                        id="userSchoolName"
                        onChange={setMailFormValue}
                        name="userSchoolName"
                        label="School/College Name"
                        type="text"
                        variant="standard"
                      />
                      <TextField
                        id="userPhone"
                        value={mailValue.userPhone}
                        onChange={setMailFormValue}
                        name="userPhone"
                        label="Phone"
                        variant="standard"
                      />
                      <TextField
                        label="Email Id"
                        value={mailValue.userMail}
                        onChange={setMailFormValue}
                        type="email"
                        name="userMail"
                        id="userMail"
                        variant="standard"
                      />
                      <TextField
                        id="standard-multiline-flexible"
                        label="Message"
                        multiline
                        rows={2}
                        value={mailValue.userMessage}
                        name="userMessage"
                        onChange={setMailFormValue}
                        variant="standard"
                      />

                      <Button
                        type="submit"
                        variant="contained"
                        className="navbar_querySubmitBtn"
                        sx={{ backgroundColor: "#fc4349" }}
                      >
                        Send Mail
                      </Button>
                    </Box>
                  </form>
                </Typography>
              </Box>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={loginBox}
            onClose={loginBoxClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={loginBox}>
              <Box
                className="loginModalBox"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: 2,
                  p: 1,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h2"
                >
                  Student <span style={{ color: "#fc4349" }}>Login</span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <form
                    onSubmit={loginSubmission}
                    noValidate
                    autoComplete="off"
                  >
                    <Box sx={{ "& > :not(style)": { mb: 1, width: "100%" } }}>
                      <TextField
                        value={loginFormValue.loginUserId}
                        onChange={setLoginForm}
                        id="loginUserId"
                        type="email"
                        name="loginUserId"
                        label="Email Id"
                        variant="standard"
                      />
                      <TextField
                        value={loginFormValue.loginUserPassword}
                        id="loginUserPassword"
                        onChange={setLoginForm}
                        name="loginUserPassword"
                        label="Password"
                        type="password"
                        variant="standard"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className="navbar_querySubmitBtn"
                        sx={{
                          backgroundColor: "#2c3e50",
                          maxWidth: "70%",
                          display: "block",
                          margin: "15px auto auto auto",
                        }}
                      >
                        Login
                      </Button>
                    </Box>
                  </form>
                </Typography>
              </Box>
            </Fade>
          </Modal>

          {/* signup box */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={signupBox}
            onClose={signupBoxClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={signupBox}>
              <Box
                className="queryModalBox"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: 2,
                  p: 1,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h2"
                >
                  Register <span style={{ color: "#fc4349" }}>Now</span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <form
                    onSubmit={signupSubmission}
                    noValidate
                    autoComplete="off"
                  >
                    <Box sx={{ "& > :not(style)": { mb: 1, width: "100%" } }}>
                      <TextField
                        value={signupFormValue.signupUserId}
                        onChange={setSignupForm}
                        id="signupUserId"
                        type="text"
                        name="signupUserId"
                        label="Email Id"
                        variant="standard"
                      />
                      <TextField
                        value={signupFormValue.signupUserPassword}
                        id="signupUserPassword"
                        onChange={setSignupForm}
                        name="signupUserPassword"
                        label="Password"
                        type="text"
                        variant="standard"
                      />
                      <TextField
                        value={signupFormValue.signupUserPassword}
                        id="signupConfirmPassword"
                        onChange={setSignupForm}
                        name="signupConfirmPassword"
                        label="Confirm Password"
                        type="text"
                        variant="standard"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className="navbar_querySubmitBtn"
                        sx={{
                          backgroundColor: "#2c3e50",
                          maxWidth: "70%",
                          display: "block",
                          margin: "15px auto auto auto",
                        }}
                      >
                        Sign Up
                      </Button>
                    </Box>
                  </form>
                </Typography>
              </Box>
            </Fade>
          </Modal>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            keepMounted
            open={Boolean(isMenuOpen)}
            onClose={closeMenu}
          >
            <Link to="/about" className="tabLink">
              <MenuItem onClick={closeMenu}>About Us</MenuItem>
            </Link>
            <Link to="/founder" className="tabLink">
              <MenuItem onClick={closeMenu}>Meet Our Founder</MenuItem>
            </Link>
            <Link to="/news" className="tabLink">
              <MenuItem onClick={closeMenu}>News</MenuItem>
            </Link>
            <Link to="/companyProfile" className="tabLink">
              <MenuItem onClick={closeMenu}>Company Profile</MenuItem>
            </Link>
          </Menu>
          <Menu
            id="tour_menu"
            anchorEl={anchorTourMenu}
            keepMounted
            open={Boolean(isOurTourOpen)}
            onClose={closeOurTourMenu}
          >
            <a
              href="/#intEducationalSection"
              className="tabLink"
              style={{ color: "#000" }}
            >
              <MenuItem onClick={closeOurTourMenu}>
                International Excursion Program
              </MenuItem>
            </a>
            <a
              href="/#domEducationalSection"
              className="tabLink"
              style={{ color: "#000" }}
            >
              <MenuItem onClick={closeOurTourMenu}>
                Domestic Excursion Program
              </MenuItem>
            </a>
          </Menu>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNavTour}
            keepMounted
            open={Boolean(isMenuOpenTour)}
            onClose={closeTourMenu}
          >
            <Link to="/parisedutour" className="tabLink">
              <MenuItem onClick={closeTourMenu}>View Itinerary</MenuItem>
            </Link>
            <MenuItem onClick={closeTourMenu}>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="images/graphics/PARIS & BRUSSELS 10 DAYS.pdf"
                download="PARIS & BRUSSELS 10 DAYS"
              >
                Download Itinerary
              </a>
            </MenuItem>
          </Menu>
          <div className="headerIcons" style={{ marginLeft: "auto" }}>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="https://www.facebook.com/memoriesmakertrip"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiFacebookLine />
              </a>
            </Button>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="https://twitter.com/memoriesmaker_"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiTwitterFill />
              </a>
            </Button>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="mailto:info@memoriesmakeredu.com"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiGoogleLine />
              </a>
            </Button>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="https://instagram.com/memoriesmaker2016"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiInstagramLine />
              </a>
            </Button>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                display: { xs: "none", md: "inline-block" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="https://web.whatsapp.com/send?phone=+918397026046"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiWhatsappLine />
              </a>
            </Button>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                display: { xs: "inline-block", md: "none" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="whatsapp://send?phone=+918397026046"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiWhatsappLine />
              </a>
            </Button>
            <Button
              sx={{
                fontSize: "20px",
                color: "white",
                minWidth: { md: "44px", xs: "40px" },
                padding: "0",
                borderRadius: "50%",
                height: { md: "44px", xs: "40px", padding: "0" },
              }}
            >
              <a
                target="_blank"
                href="https://pin.it/3UEuXgp"
                style={{
                  color: "white",
                  textDecoration: "none",
                  maxHeight: "30px",
                }}
              >
                <RiPinterestFill />
              </a>
            </Button>
          </div>
        </Toolbar>

        <Toolbar
          className="customMainToolbar headBox"
          style={{ minHeight: "90px" }}
        >
          <Link className="link" to="/">
            <img
              src="https://memoriesmakeredu.com/images/logo/main-logo.png"
              className="mm_logo"
              style={{ width: "180px" }}
              alt="logo"
            />
          </Link>
          <p
            className="lIne"
            style={{
              fontSize: "48px",
              color: "rgb(44, 62, 80)",
              marginLeft: "-30px",
              marginRight: "30px",
            }}
          >
            |
          </p>

          <Tabs sx={{ marginLeft: "-40px", alignItems: "center" }}>
            <div className="headIng">
              <span style={{ color: "rgb(252, 67, 73)", fontWeight: "600" }}>
                Enriching Education With Travel
              </span>
            </div>
            <div className="headIngPhone">
              <span style={{ color: "rgb(252, 67, 73)", fontWeight: "600" }}>
                Enriching Education <br></br> With Travel
              </span>
            </div>
          </Tabs>
        </Toolbar>
      </AppBar>
      {/* <div className="parisBtnBox">
          <span></span>
          <span></span>
          <span></span>
          <span></span>          
        </div> */}

      <Box sx={{ minHeight: { xs: "90px", md: "110px" } }}></Box>
    </>
  );
}
export default Header;
